var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CalendarEvents", {
    scopedSlots: _vm._u([
      {
        key: "createForm",
        fn: function(ref) {
          var prefillData = ref.prefillData
          var onCreateEntityCalendar = ref.onCreateEntityCalendar
          return [
            _c("StadiumBoostCreateForm", {
              attrs: { data: prefillData },
              on: { success: onCreateEntityCalendar }
            })
          ]
        }
      },
      {
        key: "updateForm",
        fn: function(ref) {
          var selectedEntityId = ref.selectedEntityId
          var onCreateEntityCalendar = ref.onCreateEntityCalendar
          var closeUpdateForm = ref.closeUpdateForm
          return [
            _c("StadiumBoostUpdateForm", {
              attrs: { "boost-id": selectedEntityId },
              on: {
                duplicate: onCreateEntityCalendar,
                success: closeUpdateForm
              }
            })
          ]
        }
      }
    ]),
    model: {
      value: _vm.activeViewRef,
      callback: function($$v) {
        _vm.activeViewRef = $$v
      },
      expression: "activeViewRef"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }