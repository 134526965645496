<template>
    <PageTemplateEvents>
        <template v-slot:calendarEvent>
            <CalendarStadiumEvent />
        </template>
        <template v-slot:incomingEvent>
            <StadiumBoosts :is-past="false" />
        </template>
        <template v-slot:historyEvent>
            <StadiumBoosts :is-past="true" />
        </template>
    </PageTemplateEvents>
</template>
<script>
import StadiumBoosts from '@/components/StadiumBoosts.vue'
import PageTemplateEvents from '@/components/PageTemplateEvents.vue'
import CalendarStadiumEvent from '@/components/CalendarStadiumEvent.vue'

export default {
    components: {
        StadiumBoosts,
        PageTemplateEvents,
        CalendarStadiumEvent,
    },
}
</script>
