<template>
    <Employees :stadium-id="stadiumId" />
</template>
<script>
import Employees from '@/views/Employees.vue'

export default {
    components: {
        Employees,
    },
    computed: {
        stadiumId () {
            return this.$store.state.stadium.id
        },
    },
}
</script>
