<template>
    <VCard
        :avartar="sportIcon"
        :title="field.name"
        :cover-photo-url="field.coverPhotoUrl"
        :description="field.description ? field.description : '-'"
        :enable-delete="true"
        @delete="deleteField">
        <template v-for="(index, name) in $slots" v-slot:[name]>
            <slot :name="name" />
        </template>
    </VCard>
</template>
<script>
import { rootActions, Getters } from '@/store/types'
import VCard from '@/components/VCard.vue'

export default {
    components: {
        VCard,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
        onDelete: {
            type: Function,
            // We can't use default function because we lose the this binding.
            default: null,
        },
    },
    computed: {
        sportIcon () {
            return this.$store.getters[Getters.stadium.GET_SPORT](this.field.sportId)
                ? this.$store.getters[Getters.stadium.GET_SPORT](this.field.sportId).iconUrl
                : null
        },
    },
    methods: {
        deleteField () {
            if (this.onDelete) {
                this.onDelete()
            } else {
                this.$store.dispatch(rootActions.DELETE_STADIUM_FIELD, this.field.id)
            }
        },
    },
}
</script>
