<template>
    <div>
        <VModal
            v-model="visibleExclusiveProductForm"
            title="สร้างข้อเสนอพิเศษ">
            <StadiumBoostCreateForm @success="onCreateEntity" />
        </VModal>
        <div>
            <a-row type="flex">
                <a-col>
                    <a-button
                        type="link"
                        icon="arrow-left"
                        @click="()=>this.$router.push('/chats')" />
                </a-col>
                <a-col flex="auto">
                    <h3>{{ chatRoomName }}</h3>
                </a-col>
                <a-col>
                    <a-avatar
                        v-for="member in chatRoomMemberExcludeMe"
                        :key="member.id"
                        :src="member.profilePhotoUrl" />
                </a-col>
            </a-row>
        </div>
        <VListLoadMore
            v-slot="{ item }"
            :data-source="dataSource"
            :loading="loading"
            :is-last-page="isLastPage"
            :page-size="size"
            :load-more="onLoadMore">
            <StadiumChatRoomMsgListTile :data="item" />
        </VListLoadMore>
        <div>
            <ProductCard v-if="product" product="product" />
            <a-row type="flex">
                <a-col>
                    <a-button
                        type="primary"
                        shape="circle"
                        icon="plus"
                        @click="openExclusiveProductForm" />
                </a-col>
                <a-col flex="auto">
                    <a-textarea
                        v-model="msg"
                        placeholder="ส่งข้อความ..."
                        :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
import EntitiesPagination from '@/mixins/EntitiesPagination'
import VListLoadMore from '@/components/VListLoadMore.vue'
import { rootActions, Getters } from '@/store/types'
import StadiumChatRoomMsgListTile from '@/components/StadiumChatRoomMsgListTile.vue'
import ProductCard from '@/components/ProductCard.vue'
import StadiumBoostCreateForm from '@/components/StadiumBoostCreateForm.vue'
import VModal from '@/components/VModal.vue'

export default {
    components: {
        VListLoadMore,
        StadiumChatRoomMsgListTile,
        ProductCard,
        StadiumBoostCreateForm,
        VModal,
    },
    mixins: [EntitiesPagination],
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            msg: '',
            productId: null,
            visibleExclusiveProductForm: false,
        }
    },
    computed: {
        chatRoom () {
            return this.$store.getters[Getters.chat.GET_CHATROOM](this.id)
        },
        chatRoomName () {
            return this.$store.getters[Getters.chat.GET_CHATROOM_NAME](this.id)
        },
        chatRoomMemberExcludeMe () {
            return this.$store.getters[Getters.chat.GET_CHATROOM_MEMBERS_EXCLUDE_ME](this.id)
        },
        me () {
            return this.$store.state.auth.me
        },
        product () {
            return this.$store.getters[Getters.products.GET_PRODUCT](this.productId)
        },
    },
    created () {
        this.$store.dispatch(
            rootActions.LOAD_CHATROOM, this.id,
        )
    },
    methods: {
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_CHATROOM_MSGS, { chatroomId: this.id, page, size },
            )
            return res
        },
        getAllEntities () {
            return this.$store.state.chat.chatroomMsgs
        },
        openExclusiveProductForm () {
            this.visibleExclusiveProductForm = true
        },
        onCreateEntity (entity) {
            this.productId = entity.id
        },
    },
}
</script>
