var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: "จัดการสมาชิก",
            width: "50%",
            centered: "centered",
            footer: null,
            "destroy-on-close": ""
          },
          model: {
            value: _vm.visibleManageMemberForm,
            callback: function($$v) {
              _vm.visibleManageMemberForm = $$v
            },
            expression: "visibleManageMemberForm"
          }
        },
        [
          _c("ManageMemberForm", {
            attrs: { id: _vm.selectedEntityId },
            on: { success: _vm.closeManageMemberForm }
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "แก้ไขลูกค้าออฟไลน์",
            width: "50%",
            centered: "centered",
            footer: null,
            "destroy-on-close": ""
          },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("CustomerUpdateForm", {
            attrs: { id: _vm.selectedEntityId },
            on: { success: _vm.closeUpdateForm }
          })
        ],
        1
      ),
      _c("VTable", {
        staticClass: "customers__table",
        attrs: {
          "row-key": "id",
          bordered: "",
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          loading: _vm.loading,
          pagination: _vm.pagination,
          "custom-row": _vm.createClickToEditRow
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "memberType",
            fn: function(ref) {
              var text = ref.text
              var record = ref.record
              return [
                text
                  ? _c(
                      "div",
                      {
                        staticClass: "customers__member-type",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openManageMemberForm(record.id)
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "customers__member-type-action",
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.openManageMemberForm(record.id)
                              }
                            }
                          },
                          [_c("a-icon", { attrs: { type: "form" } })],
                          1
                        ),
                        _c("div", {
                          staticClass: "customers__member-type-color",
                          style: {
                            backgroundColor: text.color,
                            maxWidth: "72px",
                            width: "100%"
                          }
                        }),
                        _c(
                          "span",
                          { staticClass: "customers__member-type-name" },
                          [_vm._v("  " + _vm._s(text.name) + " ")]
                        )
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "customers__member-type",
                        style: { textAlign: "center" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openManageMemberForm(record.id)
                          }
                        }
                      },
                      [
                        _c("a", { attrs: { href: "javascript:;" } }, [
                          _vm._v("เพิ่ม")
                        ])
                      ]
                    )
              ]
            }
          },
          {
            key: "date",
            fn: function(ref) {
              var text = ref.text
              var record = ref.record
              return [
                record.editable
                  ? _c(
                      "div",
                      { class: { "has-error": record.error } },
                      [
                        _c("a-date-picker", {
                          attrs: { value: text, format: "DD/MM/YYYY" },
                          on: {
                            change: function($event) {
                              return _vm.handleChange(
                                record.id,
                                "dateOfBirth",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  : [
                      _vm._v(
                        "\n                " +
                          _vm._s(text.format("DD/MM/YYYY")) +
                          "\n                  \n                "
                      ),
                      _c("a-icon", { attrs: { type: "calendar" } })
                    ]
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var record = ref.record
              return [
                _vm.dataSource.length
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "ยืนยันการลบ?" },
                        on: {
                          confirm: function() {
                            return _vm.onConfirmDelete(record)
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [_vm._v("ลบ")]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }