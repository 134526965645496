<template>
    <div>
        <a-modal
            v-model="visibleManageMemberForm"
            title="จัดการสมาชิก"
            width="50%"
            centered="centered"
            :footer="null"
            destroy-on-close>
            <ManageMemberForm :id="selectedEntityId" @success="closeManageMemberForm" />
        </a-modal>
        <a-modal
            v-model="visibleUpdateForm"
            title="แก้ไขลูกค้าออฟไลน์"
            width="50%"
            centered="centered"
            :footer="null"
            destroy-on-close>
            <CustomerUpdateForm :id="selectedEntityId" @success="closeUpdateForm" />
        </a-modal>
        <VTable
            class="customers__table"
            row-key="id"
            bordered=""
            :data-source="dataSource"
            :columns="columns"
            :loading="loading"
            :pagination="pagination"
            :custom-row="createClickToEditRow"
            @change="handleTableChange">
            <!-- <template
                v-for="col in getTextColumns()"
                :slot="col.dataIndex"
                slot-scope="text">
                {{ text || '-' }}
            </template> -->
            <template
                v-slot:memberType="{ text,record }">
                <div
                    v-if="text"
                    class="customers__member-type"
                    @click.stop="openManageMemberForm(record.id)">
                    <a
                        class="customers__member-type-action"
                        href="javascript:;"
                        @click.stop="openManageMemberForm(record.id)"><a-icon type="form" /></a>
                    <div
                        class="customers__member-type-color"
                        :style="{ backgroundColor: text.color,maxWidth: '72px',width: '100%' }" />
                    <span class="customers__member-type-name">  {{ text.name }} </span>
                </div>
                <div
                    v-else
                    :style="{ textAlign: 'center' }"
                    class="customers__member-type"
                    @click.stop="openManageMemberForm(record.id)">
                    <a href="javascript:;">เพิ่ม</a>
                </div>
            </template>
            <template
                v-slot:date="{ text,record }">
                <div
                    v-if="record.editable"
                    :class="{ 'has-error': record.error }">
                    <a-date-picker
                        :value="text"
                        format="DD/MM/YYYY"
                        @change="handleChange(record.id,'dateOfBirth', $event)" />
                </div>
                <template v-else>
                    {{ text.format('DD/MM/YYYY') }}
                    &nbsp;&nbsp;
                    <a-icon
                        type="calendar" />
                </template>
            </template>
            <template
                v-slot:operation="{ record }">
                <a-popconfirm
                    v-if="dataSource.length"
                    title="ยืนยันการลบ?"
                    @confirm="() => onConfirmDelete(record)">
                    <a href="javascript:;" @click.stop>ลบ</a>
                </a-popconfirm>
            </template>
        </VTable>
    </div>
</template>

<script>
import moment from 'moment'
import { rootActions } from '@/store/types'
import ManageMemberForm from '@/components/ManageMemberForm.vue'
import VTable from '@/components/VTable.vue'

import MixinTable from '@/mixins/MixinTable'
import CustomerUpdateForm from '@/components/CustomerUpdateForm.vue'

export default {
    components: {
        CustomerUpdateForm,
        ManageMemberForm,
        VTable,
    },
    mixins: [MixinTable],
    data () {
        return {
            moment,
            query: '',
            visibleManageMemberForm: false,
            columns: [
                {
                    title: 'ประเภทสมาชิก',
                    dataIndex: 'stadiumMembers[0]',
                    width: '10%',
                    scopedSlots: { customRender: 'memberType' },
                },
                {
                    title: 'ชื่อจริง',
                    dataIndex: 'firstName',
                    scopedSlots: { customRender: 'firstName' },
                },
                {
                    title: 'นามสกุล',
                    dataIndex: 'lastName',
                    scopedSlots: { customRender: 'lastName' },
                },
                {
                    title: 'ชื่อเล่น',
                    dataIndex: 'nickName',
                    width: '140px',
                    scopedSlots: { customRender: 'nickName' },
                },
                // {
                //     title: 'Birthday',
                //     dataIndex: 'dateOfBirth',
                //     width: '140px',
                //     scopedSlots: { customRender: 'date' },
                // },
                {
                    title: 'เบอร์โทร',
                    dataIndex: 'tel',
                    width: '10%',
                    scopedSlots: { customRender: 'tel' },
                },
                {
                    title: 'อีเมล(Email)',
                    dataIndex: 'email',
                    scopedSlots: { customRender: 'email' },
                },
                {
                    title: 'จดบันทึก(Note)',
                    dataIndex: 'note',
                    scopedSlots: { customRender: 'note' },
                },
                {
                    title: 'การจัดการ',
                    dataIndex: 'operation',
                    width: '10%',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
        }
    },
    methods: {
        openManageMemberForm (id) {
            this.selectedEntityId = id
            this.visibleManageMemberForm = true
        },
        closeManageMemberForm () {
            this.visibleManageMemberForm = false
        },
        async delete (id) {
            const res = await this.$store.dispatch(rootActions.DELETE_CUSTOMER, id)
            return res
        },
        getTextColumns () {
            return this.columns.filter((item) => item.scopedSlots.customRender !== 'date'
                 && item.scopedSlots.customRender !== 'membership')
        },
        async searchCustomer (query) {
            this.loading = true
            await this.$store.dispatch(rootActions.SEARCH_CUSTOMER, query)
            this.loading = false
        },
        async save (id) {
            const target = this.dataSource.find(((item) => id === item.id))
            if (target) {
                const res = await this.$store.dispatch(
                    rootActions.UPDATE_CUSTOMER,
                    target,
                )
                return res.isSuccess()
            }
            return false
        },
    },
}
</script>
<style lang="stylus">
$membershipHeight = 32px

.customers__sync-button
    cursor: pointer

.customers__table
    clear: both

.customers
    .ant-calendar-picker
        width: 120px

.customers__customer-operations a
    margin-right 8px

.customers__top-bar
    margin-bottom 24px
.customers__member-type
    display inline-block
    width 100%
    > *
        display inline-block
        vertical-align middle
.customers__member-type-name
    margin-left  8px
    line-height $membershipHeight
.customers__member-type-color
    height $membershipHeight
    overflow hidden
    width auto
.customers__member-type-action
    float right
    line-height $membershipHeight
    margin-left  16px
.customers__member-type
    cursor: pointer
</style>
