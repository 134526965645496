var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VFormManageMember", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _c("TableMemberTypesActive", {
                  attrs: { "customer-id": _vm.id }
                })
              ]
            },
            proxy: true
          },
          {
            key: "operation",
            fn: function(ref) {
              var record = ref.text.text.record
              return [
                _c(
                  "a-popconfirm",
                  {
                    on: {
                      confirm: function() {
                        return _vm.onRegistor(record.id)
                      }
                    }
                  },
                  [
                    _c("template", { slot: "title" }, [
                      _c("h4", [_vm._v("Are you Sure to Registor? ")]),
                      _c("p", [
                        _vm._v("\n                        if you "),
                        _c("strong", [_vm._v("ALREADY HAVE")]),
                        _vm._v(" any member type, "),
                        _c("br"),
                        _vm._v("\n                        it will be "),
                        _c("strong", [_vm._v("REMOVE")]),
                        _vm._v("\n                        and "),
                        _c("strong", [_vm._v("REPLACE")]),
                        _vm._v(
                          "\n                        with this member type.\n                    "
                        )
                      ])
                    ]),
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        }
                      },
                      [_vm._v("\n                    สมัคร\n                ")]
                    )
                  ],
                  2
                ),
                _c(
                  "a-popconfirm",
                  {
                    attrs: { title: "ยืนยันการลบ?" },
                    on: {
                      confirm: function() {
                        return _vm.onDelete(record.id)
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "table__operation",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        }
                      },
                      [_vm._v("ลบ")]
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }