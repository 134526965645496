<template>
    <VPage title="รายการสนาม">
        <StadiumFields />
    </VPage>
</template>
<script>
import VPage from '@/components/VPage.vue'
import StadiumFields from '@/components/StadiumFields.vue'

export default {
    components: {
        StadiumFields,
        VPage,
    },
}
</script>
