<template>
    <a-list>
        <RecycleScroller
            v-infinite-scroll="onLoadMore"
            style="height: 400px"
            :items="dataSource"
            :item-size="64"
            key-field="id"
            :infinite-scroll-disabled="isLastPage"
            :infinite-scroll-distance="0">
            <template v-slot="{ item }">
                <slot v-bind="{ item }" />
            </template>
            <template v-slot:after>
                <div
                    v-if="!isLastPage"
                    :style="{
                        textAlign: 'center',
                        marginTop: '12px',
                        height: '32px',
                        lineHeight: '32px' }">
                    <a-spin />
                    โหลดเพิ่มเติม
                </div>
                <div
                    v-if="isLastPage"
                    :style="{
                        textAlign: 'center',
                        marginTop: '12px',
                        height: '32px',
                        lineHeight: '32px' }">
                    รายการสุดท้าย
                </div>
            </template>
        </RecycleScroller>
    </a-list>
</template>
<script>
import infiniteScroll from 'vue-infinite-scroll'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

export default {
    directives: { infiniteScroll },
    components: {
        RecycleScroller,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        dataSource: {
            type: Array,
            required: true,
        },
        isLastPage: {
            type: Boolean,
            default: false,
        },
        pageSize: {
            type: Number,
            required: true,
        },
        loadMore: {
            type: Function,
            // We can't use default function because we lose the this binding.
            default: null,
        },
    },
    methods: {
        onLoadMore () {
            if (this.isLastPage) return
            this.loadMore()
        },
    },
}
</script>
