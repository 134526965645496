var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModal",
        {
          attrs: { title: "สร้างข้อเสนอพิเศษ" },
          model: {
            value: _vm.visibleExclusiveProductForm,
            callback: function($$v) {
              _vm.visibleExclusiveProductForm = $$v
            },
            expression: "visibleExclusiveProductForm"
          }
        },
        [_c("StadiumBoostCreateForm", { on: { success: _vm.onCreateEntity } })],
        1
      ),
      _c(
        "div",
        [
          _c(
            "a-row",
            { attrs: { type: "flex" } },
            [
              _c(
                "a-col",
                [
                  _c("a-button", {
                    attrs: { type: "link", icon: "arrow-left" },
                    on: {
                      click: function() {
                        return this$1.$router.push("/chats")
                      }
                    }
                  })
                ],
                1
              ),
              _c("a-col", { attrs: { flex: "auto" } }, [
                _c("h3", [_vm._v(_vm._s(_vm.chatRoomName))])
              ]),
              _c(
                "a-col",
                _vm._l(_vm.chatRoomMemberExcludeMe, function(member) {
                  return _c("a-avatar", {
                    key: member.id,
                    attrs: { src: member.profilePhotoUrl }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("VListLoadMore", {
        attrs: {
          "data-source": _vm.dataSource,
          loading: _vm.loading,
          "is-last-page": _vm.isLastPage,
          "page-size": _vm.size,
          "load-more": _vm.onLoadMore
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("StadiumChatRoomMsgListTile", { attrs: { data: item } })
              ]
            }
          }
        ])
      }),
      _c(
        "div",
        [
          _vm.product
            ? _c("ProductCard", { attrs: { product: "product" } })
            : _vm._e(),
          _c(
            "a-row",
            { attrs: { type: "flex" } },
            [
              _c(
                "a-col",
                [
                  _c("a-button", {
                    attrs: { type: "primary", shape: "circle", icon: "plus" },
                    on: { click: _vm.openExclusiveProductForm }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { flex: "auto" } },
                [
                  _c("a-textarea", {
                    attrs: {
                      placeholder: "ส่งข้อความ...",
                      "auto-size": { minRows: 3, maxRows: 5 }
                    },
                    model: {
                      value: _vm.msg,
                      callback: function($$v) {
                        _vm.msg = $$v
                      },
                      expression: "msg"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }