var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-list-item",
    [
      _c(
        "a-list-item-meta",
        { attrs: { description: _vm.data.message } },
        [
          _c(
            "div",
            {
              attrs: { slot: "title", href: "https://www.antdv.com/" },
              slot: "title"
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.fromUser.displayName) +
                  "\n        "
              )
            ]
          ),
          _c("a-avatar", {
            attrs: { slot: "avatar", src: _vm.fromUser.profilePhotoUrl },
            slot: "avatar"
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }