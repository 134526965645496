var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-list",
    [
      _c("RecycleScroller", {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.onLoadMore,
            expression: "onLoadMore"
          }
        ],
        staticStyle: { height: "400px" },
        attrs: {
          items: _vm.dataSource,
          "item-size": 64,
          "key-field": "id",
          "infinite-scroll-disabled": _vm.isLastPage,
          "infinite-scroll-distance": 0
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var item = ref.item
                return [_vm._t("default", null, null, { item: item })]
              }
            },
            {
              key: "after",
              fn: function() {
                return [
                  !_vm.isLastPage
                    ? _c(
                        "div",
                        {
                          style: {
                            textAlign: "center",
                            marginTop: "12px",
                            height: "32px",
                            lineHeight: "32px"
                          }
                        },
                        [
                          _c("a-spin"),
                          _vm._v(
                            "\n                โหลดเพิ่มเติม\n            "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLastPage
                    ? _c(
                        "div",
                        {
                          style: {
                            textAlign: "center",
                            marginTop: "12px",
                            height: "32px",
                            lineHeight: "32px"
                          }
                        },
                        [
                          _vm._v(
                            "\n                รายการสุดท้าย\n            "
                          )
                        ]
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }