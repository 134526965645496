<template>
    <div>
        <VChatRoom
            :id="selectedRoomId"
            :key="selectedRoomId"
            v-slot="chatroom">
            <ChatWindow
                :show-search="false"
                :auto-scroll="{
                    send: { new: true, newAfterScrollUp: true },
                    receive: { new: true, newAfterScrollUp: true }
                }"
                :message-actions="[]"
                :show-add-room="false"
                :show-audio="false"
                :show-files="false"
                :show-emojis="false"
                :show-reaction-emojis="false"
                :load-first-room="false"
                :rooms="rooms"
                :messages="mergeMsgsToNewMsg (chatroom.msgs,chatroom.newMsgs)"
                :current-user-id="me.id"
                :room-id="selectedRoomId"
                :room-actions="roomActions"
                :loading-rooms="firstPageLoading"
                :rooms-loaded="roomsLoaded"
                :messages-loaded="chatroom.isLastPage"
                :menu-actions="menuActions"
                :message-selection-actions="messageSelectionActions"
                :textarea-action-enabled="true"
                @textarea-action-handler="chatroom.handleCustomActions"
                @send-message="({ roomId, content })=> chatroom.onSendMessage(roomId, content)"
                @fetch-more-rooms="onFetchMoreRoom"
                @fetch-messages="
                    ({ room, options }) => onFetchMsgs(room, options,chatroom.onLoadMore)
                ">
                <template #custom-action-icon>
                    <a-icon type="plus-circle" :style="{ fontSize: '24px', color: '#08c' }" />
                </template>
                <template #custom-message-attachments="{ attachments }">
                    <div v-if="attachments && attachments.length > 0" style="width:380px">
                        <ProductCard
                            :product="getProductFromId(attachments[0].id)"
                            :disable-update="true"
                            :enable-click-to-open-update-form="true"
                            @duplicate="(value)=>chatroom.onCreatePrivateProduct(value)" />
                    </div>
                </template>
            </ChatWindow>
        </VChatRoom>
    </div>
</template>
<script>
import EntitiesPagination from '@/mixins/EntitiesPagination'
import ChatWindow from 'vue-advanced-chat'
import { rootActions, Getters } from '@/store/types'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import VChatRoom from '@/components/VChatRoom.vue'
import ProductCard from '@/components/ProductCard.vue'
import moment from 'moment'

export default {
    components: {
        ChatWindow,
        VChatRoom,
        ProductCard,
    },
    mixins: [EntitiesPagination],
    data () {
        return {
            size: 9,
            selectedRoomId: null,
            roomsLoaded: false,
            messagesLoaded: false,
            roomActions: [
                // { name: 'inviteUser', title: 'Invite User' },
                // { name: 'removeUser', title: 'Remove User' },
                // { name: 'deleteRoom', title: 'Delete Room' },
            ],
            menuActions: [
                // { name: 'inviteUser', title: 'Invite User' },
                // { name: 'removeUser', title: 'Remove User' },
                // { name: 'deleteRoom', title: 'Delete Room' },
            ],
            messageSelectionActions: [{ name: 'deleteMessages', title: 'Delete' }],
            styles: { container: { borderRadius: '4px' } },
            isResetOnLoadMore: false,
            invertOrder: true,
        }
    },
    computed: {
        me () {
            return this.$store.state.auth.me
        },
        screenHeight () {
            return this.isDevice ? `${window.innerHeight }px` : 'calc(100vh - 80px)'
        },
        rooms () {
            return this.entities.map((e, idx) => {
                const members = this.getMemberOfRoom(e)
                const { recentlyMsg } = e
                return {
                    index: idx,
                    roomId: e.id,
                    roomName: e.name,
                    avatar: members[0].profilePhotoUrl,
                    lastMessage: (recentlyMsg == null || recentlyMsg === undefined) ? null
                        : this.covertRawMsgToMsg(recentlyMsg),
                    users: members.map(
                        (member) => (
                            {
                                _id: member.id,
                                username: member.username,
                                avartar: member.profilePhotoUrl,
                            }
                        ),
                    ),
                }
            })
        },
    },
    methods: {
        getProductFromId (id) {
            return this.$store.getters[Getters.products.GET_PRODUCT](id)
        },
        mergeMsgsToNewMsg (msgs, newMsgs) {
            const mergedData = [...msgs, ...newMsgs]
            return mergedData.map((raw) => this.covertRawMsgToMsg(raw))
        },
        covertRawMsgToMsg (raw) {
            const msgDateTime = raw.updatedOn
            const duration = moment.duration(moment().diff(msgDateTime))
            const dateString = duration.asDays() < 2
                ? this.$options.filters.prettyDuration(duration)
                : this.$options.filters.prettyDate(msgDateTime)
            return {
                _id: raw.id,
                content: raw.message,
                senderId: raw.fromUser.id,
                username: raw.fromUser.displayName,
                avatar: raw.fromUser.profilePhotoUrl,
                date: dateString,
                timestamp: `${dateString} ${this.$options.filters.formatTime(msgDateTime)}`,
                saved: true,
                distributed: true,
                seen: true,
                attachments: raw.productAttachment != null ? [raw.productAttachment] : [],
            }
        },
        getMemberOfRoom (room) {
            return this.$store.getters[Getters.chat.GET_CHATROOM_MEMBERS_EXCLUDE_ME](room.id)
        },
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(rootActions.LOAD_CHATROOMS, { page, size })
            return res
        },
        callbackSuccessFetchEntities () {
            if (this.page === 1) {
                const [first] = this.rooms
                if (first) this.selectedRoomId = first.roomId
            }
            if (this.isLastPage) this.roomsLoaded = true
        },
        getAllEntities () {
            return this.$store.state.chat.chatrooms
        },
        async onFetchMsgs (room, _options, process) {
            this.selectedRoomId = room.roomId
            await process()
        },
        async onFetchMoreRoom () {
            if (!this.loading) await this.onLoadMore()
        },
    },
}
</script>

<style lang="stylus">
.vac-message-container
    max-width: 100%

.vac-message-wrapper .vac-offset-current
    margin-left: 0

.vac-message-wrapper .vac-message-box
    max-width: none
</style>
