<template>
    <div class="stadium-fields">
        <VModal
            v-model="visibleCreateForm"
            title="สร้างสนาม">
            <StadiumFieldCreateForm @success="onCreateEntityPagination" />
        </VModal>
        <VModal
            v-model="visibleUpdateForm"
            title="แก้ไขข้อมูลสนาม">
            <StadiumFieldUpdateForm :field-id="selectedEntityId" @success="closeUpdateForm" />
        </VModal>
        <div class="stadium-fields__top-bar">
            <a-input-search
                placeholder="ค้นหา ชื่อสนาม ประเภทกีฬา"
                style="width: 400px;margin-right:18px;"
                @search="updateQuery"
                @change="handleQueryChange" />
            <a-button type="primary" @click="openCreateForm">
                สร้าง
            </a-button>
        </div>
        <SectionNew v-if="newEntities.length > 0">
            <VGrid
                v-slot="{ item }"
                :data-source="newEntities">
                <FieldCard
                    :field="item"
                    @click.native="openUpdateForm(item.id)" />
            </VGrid>
        </SectionNew>
        <VGrid
            v-slot="{ item }"
            :loading="loading"
            :data-source="dataSource">
            <FieldCard
                :field="item"
                @click.native="openUpdateForm(item.id)" />
        </VGrid>
        <a-pagination
            v-model="page"
            :page-size="size"
            :total="total" />
    </div>
</template>
<script>
import StadiumFieldCreateForm from '@/components/StadiumFieldCreateForm.vue'
import StadiumFieldUpdateForm from '@/components/StadiumFieldUpdateForm.vue'
import VGrid from '@/components/VGrid.vue'
import VModal from '@/components/VModal.vue'
import SectionNew from '@/components/SectionNew.vue'
import FieldCard from '@/components/FieldCard.vue'
import { rootActions } from '@/store/types'
import { includeText } from '@/utils/utils'
import EntitiesPagination from '@/mixins/EntitiesPagination'

export default {
    components: {
        StadiumFieldCreateForm,
        StadiumFieldUpdateForm,
        SectionNew,
        FieldCard,
        VModal,
        VGrid,
    },
    mixins: [EntitiesPagination],
    created () {
        this.$store.dispatch(rootActions.LOAD_SPORTS)
    },
    methods: {
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(rootActions.LOAD_FIELDS, { page, size })
            return res
        },
        getAllEntities () {
            return this.$store.state.stadium.fields
        },
        search (query) {
            if (!query) return [...this.entities]
            const matches = this.entities.filter((item) => includeText(item.name, query)
            || includeText(item.sport, query))
            return matches
        },
    },
}
</script>
<style lang="stylus">
.stadium-fields__top-bar
    margin-bottom 24px
.stadium-fields__new-list
    background-color #fffbe6
    padding 8px
    margin-bottom 16px
</style>
