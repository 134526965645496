var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "1" } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "ข้อมูลทั่วไป" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "customer-create-form",
                  attrs: { form: _vm.form, layout: "horizontal" },
                  on: { submit: _vm.handleSubmit }
                },
                [
                  _c("PeopleCreateAndUpdateFormItems", {
                    attrs: {
                      form: _vm.form,
                      data: _vm.customer,
                      "enable-address-input": false,
                      "enable-note-input": true,
                      "action-type": "update"
                    }
                  }),
                  _c(
                    "a-form-item",
                    {
                      style: { marginBottom: 0 },
                      attrs: { "wrapper-col": { span: 12, offset: 4 } }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "customer-create-form__save-button",
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.isProcessing
                          }
                        },
                        [
                          _vm._v(
                            "\n                        บันทึก\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "ประเภทสมาชิก" } },
            [_c("ManageMemberForm", { attrs: { id: _vm.customer.id } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }