var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModal",
        {
          attrs: { title: "สร้างการโปรโมท" },
          model: {
            value: _vm.visibleCreatePrivateProductForm,
            callback: function($$v) {
              _vm.visibleCreatePrivateProductForm = $$v
            },
            expression: "visibleCreatePrivateProductForm"
          }
        },
        [
          _c("StadiumBoostCreateForm", {
            attrs: { "is-offer-mode": true },
            on: { success: _vm.onCreatePrivateProduct }
          })
        ],
        1
      ),
      _vm._t("default", null, {
        onCreatePrivateProduct: _vm.onCreatePrivateProduct,
        msgs: _vm.dataSource,
        newMsgs: _vm.newEntitiesDataSource,
        onSendMessage: _vm.onSendMessage,
        isLastPage: _vm.isLastPage,
        onLoadMore: _vm.onLoadMore,
        handleCustomActions: _vm.handleCustomActions
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }