<template>
    <a-list-item>
        <a-list-item-meta
            :description="data.message">
            <div slot="title" href="https://www.antdv.com/">
                {{ fromUser.displayName }}
            </div>
            <a-avatar
                slot="avatar"
                :src="fromUser.profilePhotoUrl" />
        </a-list-item-meta>
    </a-list-item>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        fromUser () {
            return this.data.fromUser
        },
    },
}
</script>
