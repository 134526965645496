var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PageTemplateEvents", {
    scopedSlots: _vm._u([
      {
        key: "calendarEvent",
        fn: function() {
          return [_c("CalendarStadiumEvent")]
        },
        proxy: true
      },
      {
        key: "incomingEvent",
        fn: function() {
          return [_c("StadiumBoosts", { attrs: { "is-past": false } })]
        },
        proxy: true
      },
      {
        key: "historyEvent",
        fn: function() {
          return [_c("StadiumBoosts", { attrs: { "is-past": true } })]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }