<template>
    <div>
        <VFormManageMember>
            <template #header>
                <TableMemberTypesActive :customer-id="id" />
            </template>
            <template
                v-slot:operation="{ text: { text: { record } } }">
                <a-popconfirm
                    @confirm="() => onRegistor(record.id)">
                    <template slot="title">
                        <h4>Are you Sure to Registor? </h4>
                        <p>
                            if you <strong>ALREADY HAVE</strong> any member type, <br>
                            it will be <strong>REMOVE</strong>
                            and <strong>REPLACE</strong>
                            with this member type.
                        </p>
                    </template>
                    <a-button type="primary" @click.stop>
                        สมัคร
                    </a-button>
                </a-popconfirm>
                <a-popconfirm
                    title="ยืนยันการลบ?"
                    @confirm="() => onDelete(record.id)">
                    <a
                        href="javascript:;"
                        class="table__operation"
                        @click.stop>ลบ</a>
                </a-popconfirm>
            </template>
        </VFormManageMember>
    </div>
</template>
<script>
import moment from 'moment'
import { rootActions, Getters } from '@/store/types'
import VFormManageMember from '@/components/VFormManageMember.vue'
import TableMemberTypesActive from '@/components/TableMemberTypesActive.vue'

export default {
    components: {
        VFormManageMember,
        TableMemberTypesActive,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: undefined,
        },
    },
    data () {
        return {
            visibleDetailPage: false,
            visibleHistoryPage: false,
            selectedMemberTypeId: null,
            selectedMemberType: null,
            moment,
        }
    },
    computed: {
        customer () {
            return this.$store.getters[Getters.customers.GET_CUSTOMER](this.id)
        },
    },
    methods: {
        getExpiredDate (startDate, expiredInDay) {
            // To Avoid modify original data
            const momentCopy = moment(startDate)
            // console.log(startDate)
            // console.log(expiredInDay)
            return momentCopy.add(expiredInDay, 'days')
        },
        getTextColumn () {
            return this.columns.filter((item) => item.scopedSlots.customRender !== 'color'
            && item.scopedSlots.customRender !== 'duration')
        },
        async onRegistor (memberTypeId) {
            this.currentMemberLoading = true
            const res = await this.$store.dispatch(
                rootActions.REGISTOR_MEMBER_TYPE,
                { customerId: this.id, memberTypeId },
            )
            if (res.isSuccess()) {
                this.$emit('success', res.data)
            }
            this.currentMemberLoading = false
        },
        async onDelete (id) {
            const res = await this.$store.dispatch(rootActions.DELETE_MEMBER_TYPE, id)
            return res
        },
    },
}
</script>
<style lang="stylus">
.member-types__add-button
    margin-bottom: 16px
    float: right
.member-types__member-type-color
    height 32px
.member-types__table
    clear both
.manage-member-form__current-member-type-table
    margin-bottom 16px
    clear both
.manage-member-form__history-button
    margin-bottom: 16px
    float: right
.manage-member-form__remaining-days
    display inline-block
.table__operation
    margin-left:8px
</style>
