<template>
    <VPage title="กล่องข้อความ">
        <StadiumChatRooms />
    </VPage>
</template>
<script>
import VPage from '@/components/VPage.vue'
import StadiumChatRooms from '@/components/StadiumChatRooms.vue'

export default {
    components: {
        StadiumChatRooms,
        VPage,
    },
}
</script>
