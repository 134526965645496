<template>
    <VPage title="ลูกค้าออฟไลน์">
        <a-modal
            v-model="visibleCreateForm"
            title="สร้างลูกค้าออฟไลน์"
            width="50%"
            centered="centered"
            :footer="null"
            destroy-on-close>
            <CustomerCreateForm @success="onCreateEntityPagination" />
        </a-modal>

        <div class="customers__top-bar">
            <!-- <a-input-search
                placeholder="ค้นหา ลูกค้าออฟไลน์ โดย ชื่อ นามสกุล หรือ เบอร์โทร"
                style="width: 400px;margin-right:18px;"
                enter-button
                @search="searchCustomer" /> -->
            <a-button
                type="primary"
                class="customers__add-button"
                @click="openCreateForm">
                สร้าง
            </a-button>
        </div>
        <SectionNew v-if="newEntities.length > 0">
            <TableCustomers
                :data-source="newEntities"
                :loading="loading"
                :custom-row="createClickToEditRow"
                @delete="onDeleteEntityPagination" />
        </SectionNew>
        <TableCustomers
            :data-source="entities"
            :loading="loading"
            :custom-row="createClickToEditRow"
            @delete="onDeleteEntityPagination" />

    </VPage>
</template>
<script>
import { rootActions } from '@/store/types'
import CustomerCreateForm from '@/components/CustomerCreateForm.vue'
import EntitiesPagination from '@/mixins/EntitiesPagination'
import TableCustomers from '@/components/TableCustomers.vue'
import SectionNew from '@/components/SectionNew.vue'
import VPage from '@/components/VPage.vue'

export default {
    components: {
        SectionNew,
        TableCustomers,
        VPage,
        CustomerCreateForm,
    },
    mixins: [EntitiesPagination],
    data () {
        return {
            query: '',
        }
    },
    methods: {
        getAllEntities () {
            return this.$store.state.customers.customers
        },
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(rootActions.LOAD_CUSTOMERS, { page, size })
            return res
        },
        async delete (id) {
            const res = await this.$store.dispatch(rootActions.DELETE_CUSTOMER, id)
            return res
        },
        async searchCustomer (query) {
            this.loading = true
            await this.$store.dispatch(rootActions.SEARCH_CUSTOMER, query)
            this.loading = false
        },
        handleQueryChange (e) {
            const { value } = e.target
            this.updateQuery(value)
        },
        handleChange (id, dataIndex, value) {
            const target = this.dataSource.find(((item) => id === item.id))
            if (target) {
                target[dataIndex] = value
            }
        },
        async save (id) {
            const target = this.dataSource.find(((item) => id === item.id))
            if (target) {
                const res = await this.$store.dispatch(
                    rootActions.UPDATE_CUSTOMER,
                    target,
                )
                return res.isSuccess()
            }
            return false
        },
    },
}
</script>
<style lang="stylus">

</style>
