var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VPage", { attrs: { title: "กระดานรายงาน" } }, [
    _c(
      "div",
      [
        _c(
          "a-tabs",
          { attrs: { "default-active-key": "1", type: "card" } },
          [
            _c(
              "a-tab-pane",
              { key: "1", attrs: { tab: "ตารางการจอง" } },
              [
                _c("CalendarStadiumEvent", {
                  model: {
                    value: _vm.activeView,
                    callback: function($$v) {
                      _vm.activeView = $$v
                    },
                    expression: "activeView"
                  }
                })
              ],
              1
            ),
            _c(
              "a-tab-pane",
              { key: "2", attrs: { tab: "รายการ ซื้อ/ยกเลิก" } },
              [_c("FeedTxItems")],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }