<template>
    <VPage title="ข้อมูล สเตเดียม">
        <VForm
            v-slot="{ form,data }"
            :loading="loading"
            :data="generalInfo"
            :on-submit-async="submit"
            @success="(value)=>this.$emit('success',value)">
            <StadiumCreateAndUpdateFormItems :form="form" :data="data" />
        </VForm>
    </VPage>
</template>

<script>
import { rootActions } from '@/store/types'
import StadiumCreateAndUpdateFormItems from '@/components/StadiumCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'
import VPage from '@/components/VPage.vue'

export default {
    components: {
        StadiumCreateAndUpdateFormItems,
        VForm,
        VPage,
    },
    data () {
        return {
            loading: false,
        }
    },
    computed: {
        generalInfo () {
            return this.$store.state.stadium.generalInfo
        },
    },
    async created () {
        this.loading = true
        await this.$store.dispatch(rootActions.LOAD_STADIUMS_GENERAL_INFO)
        this.loading = false
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.SAVE_STADIUM_GENERAL_INFO,
                { ...values },
            )
            return res
        },
    },
}
</script>
