<template>
    <VPage title="สิทธิ์">
        <a-table
            :data-source="dataSource"
            :columns="columns"
            :pagination="false"
            row-key="name"
            bordered>
            <template
                slot="tick"
                slot-scope="text">
                <a-icon
                    v-if="text"
                    type="check-circle"
                    theme="twoTone"
                    two-tone-color="#52c41a"
                    class="permission__check-icon" />
                <a-icon
                    v-else
                    type="minus-circle"
                    theme="twoTone"
                    two-tone-color="#f5222d"
                    class="permission__check-icon" />
            </template>
        </a-table>
    </VPage>
</template>
<script>
import { StadiumRole } from '@/utils/enum'
import VPermission from '@/mixins/VPermission'
import VPage from '@/components/VPage.vue'

export default {
    components: {
        VPage,
    },
    mixins: [VPermission],
    data () {
        return {
            dataSource: (() => {
                const results = []
                Object.keys(StadiumRole).forEach((key) => {
                    results.push({
                        name: this.$options.filters.getStadiumRolePrettyString(StadiumRole[key]),
                        value: StadiumRole[key],
                        manageEmployee: this.isHasPermissionInStadium(
                            StadiumRole[key], StadiumRole.EMPLOYEE,
                        ),
                        manageManager: this.isHasPermissionInStadium(
                            StadiumRole[key], StadiumRole.MANAGER,
                        ),
                        manageOwner: this.isHasPermissionInStadium(
                            StadiumRole[key], StadiumRole.OWNER,
                        ),
                    })
                })
                return results
            })(),
            columns: [
                {
                    title: 'ตำแหน่ง',
                    dataIndex: 'name',
                },
                {
                    title: 'สร้าง/แก้ไข/ลบ',
                    children: [
                        {
                            title: 'พนักงาน',
                            dataIndex: 'manageEmployee',
                            scopedSlots: { customRender: 'tick' },
                            align: 'center',
                        },
                        {
                            title: 'ผู้จัดการ',
                            dataIndex: 'manageManager',
                            scopedSlots: { customRender: 'tick' },
                            align: 'center',
                        },
                        {
                            title: 'เจ้าของ',
                            dataIndex: 'manageOwner',
                            scopedSlots: { customRender: 'tick' },
                            align: 'center',
                        },
                    ],
                },
            ],
        }
    },
}
</script>
<style lang="stylus">
.permission__check-icon
    font-size: 24px;
</style>
