<template>
    <CalendarEvents v-model="activeViewRef">
        <template v-slot:createForm="{ prefillData,onCreateEntityCalendar }">
            <StadiumBoostCreateForm
                :data="prefillData"
                @success="onCreateEntityCalendar" />
        </template>
        <template v-slot:updateForm="{ selectedEntityId,onCreateEntityCalendar,closeUpdateForm }">
            <StadiumBoostUpdateForm
                :boost-id="selectedEntityId"
                @duplicate="onCreateEntityCalendar"
                @success="closeUpdateForm" />
        </template>
    </CalendarEvents>
</template>
<script>
import StadiumBoostUpdateForm from '@/components/StadiumBoostUpdateForm.vue'
import StadiumBoostCreateForm from '@/components/StadiumBoostCreateForm.vue'
import CalendarEvents from '@/components/CalendarEvents.vue'
import MixinCalendar from '@/mixins/MixinCalendar'

export default {
    components: {
        CalendarEvents,
        StadiumBoostUpdateForm,
        StadiumBoostCreateForm,
    },
    mixins: [MixinCalendar],
}
</script>
