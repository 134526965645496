<template>
    <VPage title="กระดานรายงาน">
        <div>
            <a-tabs default-active-key="1" type="card">
                <a-tab-pane key="1" tab="ตารางการจอง">
                    <CalendarStadiumEvent v-model="activeView" />
                </a-tab-pane>
                <a-tab-pane key="2" tab="รายการ ซื้อ/ยกเลิก">
                    <FeedTxItems />
                </a-tab-pane>
            </a-tabs>
        </div>
    </VPage>
</template>
<script>
import VPage from '@/components/VPage.vue'
import CalendarStadiumEvent from '@/components/CalendarStadiumEvent.vue'
import FeedTxItems from '@/components/FeedTxItems.vue'

export default {
    components: {
        VPage,
        FeedTxItems,
        CalendarStadiumEvent,
    },
    data () {
        return {
            activeView: 'day',
        }
    },
}
</script>
