<template>
    <div>
        <h2>{{ title }}</h2>
        <MemberTypes
            :disable-click-to-edit-row="true"
            :data-source="membershipDataSource"
            :loading="currentMemberLoading">
            <template
                v-slot:operation="{ record }">
                <a-popconfirm
                    v-if="record"
                    @confirm="() => onRemove()">
                    <template slot="title">
                        <h4>ยืนยัน การยกเลิกสมาชิก</h4>
                        <p>
                            เหตุผล:
                            <a-textarea
                                placeholder="เหตุผล การยกเลิก"
                                auto-size
                                :value="reasonsToRemove"
                                @change="onChangereasonsToRemove" />
                        </p>
                    </template>
                    <a href="javascript:;" @click.stop>ยกเลิก</a>
                </a-popconfirm>
            </template>
        </MemberTypes>
    </div>
</template>
<script>
import { rootActions, Getters } from '@/store/types'
import MemberTypes from '@/components/MemberTypes.vue'

export default {
    components: {
        MemberTypes,
    },
    props: {
        customerId: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: 'ประเภทสมาชิกที่สมัครอยู่',
        },
    },
    data () {
        return {
            currentMemberLoading: false,
            reasonsToRemove: undefined,
        }
    },
    computed: {
        customer () {
            return this.$store.getters[Getters.customers.GET_CUSTOMER](this.customerId)
        },
        membershipDataSource () {
            return this.customer.stadiumMembers ? this.customer.stadiumMembers : []
        },
    },
    methods: {
        onChangereasonsToRemove (e) {
            this.reasonsToRemove = e.target.value
        },
        async onRemove () {
            this.currentMemberLoading = true
            const res = await this.$store.dispatch(
                rootActions.UNREGISTOR_MEMBER_TYPE,
                {
                    customerId: this.customerId,
                    reasonsToRemove: this.reasonsToRemove,
                },
            )
            if (res.isSuccess()) {
                this.reasonsToRemove = null
            }
            this.currentMemberLoading = false
        },
    },
}
</script>
