<template>
    <VPage title="กระดานรายงาน">
        <a-row :gutter="16">
            <a-col :span="8">
                <CardStadiumReport
                    :start="startToday"
                    :end="endToday"
                    :start-of-last-report="startToday.clone().subtract(1,'day')"
                    :end-of-last-report="endToday.clone().subtract(1,'day')"
                    title="วันนี้" />
            </a-col>
            <a-col :span="8">
                <CardStadiumReport
                    :start="startWeek"
                    :end="endWeek"
                    :start-of-last-report="startToday.clone().subtract(1,'day')"
                    :end-of-last-report="endToday.clone().subtract(1,'day')"
                    title="สัปดาห์นี้" />
            </a-col>
            <a-col :span="8">
                <CardStadiumReport
                    :start="startMonth"
                    :end="endMonth"
                    :start-of-last-report="startToday.clone().subtract(1,'day')"
                    :end-of-last-report="endToday.clone().subtract(1,'day')"
                    title="เดือนนี้" />
            </a-col>
        </a-row>
        <br>
        <h2>
            ดูรายงานตามเวลาที่เลือก
        </h2>
        <a-radio-group :value="reportType" @change="onModeChange">
            <a-radio-button :value="ReportType.DAILY">
                รายวัน
            </a-radio-button>
            <a-radio-button :value="ReportType.WEEKLY">
                รายสัปดาห์
            </a-radio-button>
            <a-radio-button :value="ReportType.MONTHLY">
                รายเดือน
            </a-radio-button>
        </a-radio-group>
        <a-date-picker v-if="reportType === ReportType.DAILY" @change="onDayChange" />
        <a-month-picker
            v-else-if="reportType === ReportType.MONTHLY"
            placeholder="เลือกเดือน"
            @change="onMonthChange" />
        <a-week-picker
            v-else-if="reportType === ReportType.WEEKLY"
            placeholder="เลือกสัปดาห์"
            @change="onWeekChange" />
        <br>
        <br>
        <CardStadiumReport
            v-if=" customReportStart !== null && customReportEnd != null"
            :start="customReportStart"
            :end="customReportEnd"
            :start-of-last-report="lastCustomReportStart"
            :end-of-last-report="lastCustomReportEnd" />
    </VPage>
</template>

<script>
import CardStadiumReport from '@/components/CardStadiumReport.vue'
import VPage from '@/components/VPage.vue'
import moment from 'moment'
import { ReportType } from '@/utils/enum'

export default {
    components: {
        VPage,
        CardStadiumReport,
    },
    data () {
        return {
            startToday: moment().startOf('day'),
            endToday: moment().endOf('day'),
            startWeek: moment().startOf('week'),
            endWeek: moment().endOf('week'),
            startMonth: moment().startOf('month'),
            endMonth: moment().endOf('month'),
            reportType: ReportType.MONTHLY,
            ReportType,
            customReportStart: null,
            customReportEnd: null,
            lastCustomReportStart: null,
            lastCustomReportEnd: null,
        }
    },
    methods: {
        onModeChange (e) {
            this.reportType = e.target.value
        },
        onDayChange (e) {
            const dateInDate = e
            this.customReportStart = dateInDate.clone().startOf('day')
            this.customReportEnd = dateInDate.clone().endOf('day')
            this.lastCustomReportStart = this.customReportStart.clone().subtract(1, 'day')
            this.lastCustomReportEnd = this.customReportEnd.clone().subtract(1, 'day')
        },
        onMonthChange (e) {
            const dateInMonth = e
            this.customReportStart = dateInMonth.clone().startOf('month')
            this.customReportEnd = dateInMonth.clone().endOf('month')
            this.lastCustomReportStart = this.customReportStart.clone().subtract(1, 'month')
            this.lastCustomReportEnd = this.customReportEnd.clone().subtract(1, 'month')
        },
        onWeekChange (e) {
            const dateInWeek = e
            this.customReportStart = dateInWeek.clone().startOf('week')
            this.customReportEnd = dateInWeek.clone().endOf('week')
            this.lastCustomReportStart = this.customReportStart.clone().subtract(1, 'week')
            this.lastCustomReportEnd = this.customReportEnd.clone().subtract(1, 'week')
        },
    },
}
</script>
