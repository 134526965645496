<template>
    <VPage title="ห้องแชท">
        <StadiumChatRoom :id="id" />
    </VPage>
</template>
<script>
import StadiumChatRoom from '@/components/StadiumChatRoom.vue'
import VPage from '@/components/VPage.vue'

export default {
    components: {
        StadiumChatRoom,
        VPage,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
}
</script>
