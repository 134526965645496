<template>
    <TableTxItems
        :data-source="dataSource"
        :loading="loading"
        :pagination="pagination"
        :on-refresh="onFetchEntities"
        @change="handleTableChange" />
</template>
<script>
import EntitiesPagination from '@/mixins/EntitiesPagination'
import { rootActions } from '@/store/types'
import TableTxItems from '@/components/TableTxItems.vue'

export default {
    components: {
        TableTxItems,
    },
    mixins: [EntitiesPagination],
    methods: {
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_TX_ITEMS,
                { page, size },
            )
            return res
        },
        getAllEntities () {
            return this.$store.state.txItem.txItems
        },
    },
}
</script>
