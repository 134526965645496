var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "กระดานรายงาน" } },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 8 } },
            [
              _c("CardStadiumReport", {
                attrs: {
                  start: _vm.startToday,
                  end: _vm.endToday,
                  "start-of-last-report": _vm.startToday
                    .clone()
                    .subtract(1, "day"),
                  "end-of-last-report": _vm.endToday.clone().subtract(1, "day"),
                  title: "วันนี้"
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 8 } },
            [
              _c("CardStadiumReport", {
                attrs: {
                  start: _vm.startWeek,
                  end: _vm.endWeek,
                  "start-of-last-report": _vm.startToday
                    .clone()
                    .subtract(1, "day"),
                  "end-of-last-report": _vm.endToday.clone().subtract(1, "day"),
                  title: "สัปดาห์นี้"
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 8 } },
            [
              _c("CardStadiumReport", {
                attrs: {
                  start: _vm.startMonth,
                  end: _vm.endMonth,
                  "start-of-last-report": _vm.startToday
                    .clone()
                    .subtract(1, "day"),
                  "end-of-last-report": _vm.endToday.clone().subtract(1, "day"),
                  title: "เดือนนี้"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("br"),
      _c("h2", [_vm._v("\n        ดูรายงานตามเวลาที่เลือก\n    ")]),
      _c(
        "a-radio-group",
        { attrs: { value: _vm.reportType }, on: { change: _vm.onModeChange } },
        [
          _c("a-radio-button", { attrs: { value: _vm.ReportType.DAILY } }, [
            _vm._v("\n            รายวัน\n        ")
          ]),
          _c("a-radio-button", { attrs: { value: _vm.ReportType.WEEKLY } }, [
            _vm._v("\n            รายสัปดาห์\n        ")
          ]),
          _c("a-radio-button", { attrs: { value: _vm.ReportType.MONTHLY } }, [
            _vm._v("\n            รายเดือน\n        ")
          ])
        ],
        1
      ),
      _vm.reportType === _vm.ReportType.DAILY
        ? _c("a-date-picker", { on: { change: _vm.onDayChange } })
        : _vm.reportType === _vm.ReportType.MONTHLY
        ? _c("a-month-picker", {
            attrs: { placeholder: "เลือกเดือน" },
            on: { change: _vm.onMonthChange }
          })
        : _vm.reportType === _vm.ReportType.WEEKLY
        ? _c("a-week-picker", {
            attrs: { placeholder: "เลือกสัปดาห์" },
            on: { change: _vm.onWeekChange }
          })
        : _vm._e(),
      _c("br"),
      _c("br"),
      _vm.customReportStart !== null && _vm.customReportEnd != null
        ? _c("CardStadiumReport", {
            attrs: {
              start: _vm.customReportStart,
              end: _vm.customReportEnd,
              "start-of-last-report": _vm.lastCustomReportStart,
              "end-of-last-report": _vm.lastCustomReportEnd
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }