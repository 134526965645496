<template>
    <div>
        <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="ข้อมูลทั่วไป">
                <a-form
                    class="customer-create-form"
                    :form="form"
                    layout="horizontal"
                    @submit="handleSubmit">
                    <PeopleCreateAndUpdateFormItems
                        :form="form"
                        :data="customer"
                        :enable-address-input="false"
                        :enable-note-input="true"
                        action-type="update" />
                    <a-form-item
                        :wrapper-col="{ span: 12, offset: 4 }"
                        :style="{ marginBottom: 0 }">
                        <a-button
                            class="customer-create-form__save-button"
                            type="primary"
                            html-type="submit"
                            :loading="isProcessing">
                            บันทึก
                        </a-button>
                    </a-form-item>
                </a-form>
            </a-tab-pane>
            <a-tab-pane key="2" tab="ประเภทสมาชิก">
                <ManageMemberForm :id="customer.id" />
            </a-tab-pane>
        </a-tabs>
    </div>
</template>
<script>
import { rootActions, Getters } from '@/store/types'
import PeopleCreateAndUpdateFormItems from '@/components/PeopleCreateAndUpdateFormItems.vue'
import Form from '@/mixins/Form'
import ManageMemberForm from '@/components/ManageMemberForm.vue'

export default {
    components: {
        PeopleCreateAndUpdateFormItems,
        ManageMemberForm,
    },
    mixins: [Form],
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    computed: {
        customer () {
            return this.$store.getters[Getters.customers.GET_CUSTOMER](this.id)
        },
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.UPDATE_CUSTOMER, { id: this.id, ...values },
            )
            return res
        },
    },
}
</script>
