var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "ลูกค้าออฟไลน์" } },
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: "สร้างลูกค้าออฟไลน์",
            width: "50%",
            centered: "centered",
            footer: null,
            "destroy-on-close": ""
          },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [
          _c("CustomerCreateForm", {
            on: { success: _vm.onCreateEntityPagination }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "customers__top-bar" },
        [
          _c(
            "a-button",
            {
              staticClass: "customers__add-button",
              attrs: { type: "primary" },
              on: { click: _vm.openCreateForm }
            },
            [_vm._v("\n            สร้าง\n        ")]
          )
        ],
        1
      ),
      _vm.newEntities.length > 0
        ? _c(
            "SectionNew",
            [
              _c("TableCustomers", {
                attrs: {
                  "data-source": _vm.newEntities,
                  loading: _vm.loading,
                  "custom-row": _vm.createClickToEditRow
                },
                on: { delete: _vm.onDeleteEntityPagination }
              })
            ],
            1
          )
        : _vm._e(),
      _c("TableCustomers", {
        attrs: {
          "data-source": _vm.entities,
          loading: _vm.loading,
          "custom-row": _vm.createClickToEditRow
        },
        on: { delete: _vm.onDeleteEntityPagination }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }