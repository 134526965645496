var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "สิทธิ์" } },
    [
      _c("a-table", {
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          pagination: false,
          "row-key": "name",
          bordered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "tick",
            fn: function(text) {
              return [
                text
                  ? _c("a-icon", {
                      staticClass: "permission__check-icon",
                      attrs: {
                        type: "check-circle",
                        theme: "twoTone",
                        "two-tone-color": "#52c41a"
                      }
                    })
                  : _c("a-icon", {
                      staticClass: "permission__check-icon",
                      attrs: {
                        type: "minus-circle",
                        theme: "twoTone",
                        "two-tone-color": "#f5222d"
                      }
                    })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }