var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TableTxItems", {
    attrs: {
      "data-source": _vm.dataSource,
      loading: _vm.loading,
      pagination: _vm.pagination,
      "on-refresh": _vm.onFetchEntities
    },
    on: { change: _vm.handleTableChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }