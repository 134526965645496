<template>
    <div>
        <VModal
            v-model="visibleCreatePrivateProductForm"
            title="สร้างการโปรโมท">
            <StadiumBoostCreateForm :is-offer-mode="true" @success="onCreatePrivateProduct" />
        </VModal>
        <slot
            :onCreatePrivateProduct="onCreatePrivateProduct"
            :msgs="dataSource"
            :newMsgs="newEntitiesDataSource"
            :onSendMessage="onSendMessage"
            :isLastPage="isLastPage"
            :onLoadMore="onLoadMore"
            :handleCustomActions="handleCustomActions" />
    </div>
</template>
<script>
import EntitiesPagination from '@/mixins/EntitiesPagination'
import { rootActions } from '@/store/types'
import VModal from '@/components/VModal.vue'
import StadiumBoostCreateForm from '@/components/StadiumBoostCreateForm.vue'

export default {
    components: {
        VModal,
        StadiumBoostCreateForm,
    },
    mixins: [EntitiesPagination],
    props: {
        id: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            msg: '',
            invertOrder: true,
            isLoadMoreMode: true,
            // Page start at 0 because we will trigger load with loadMoreFunction
            //  Which is + page by 1 before load
            page: 0,
            visibleCreatePrivateProductForm: false,
            offer: null,
        }
    },
    watch: {
        id (newValue) {
            if (newValue) {
                this.$store.dispatch(
                    rootActions.LOAD_CHATROOM, newValue,
                )
            }
        },
    },
    methods: {
        onCreatedComponent () {
            // disable fetch at create because we will run loadMore at other component
        },
        async fetchEntityPagination () {
            const { page, size, offer } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_CHATROOM_MSGS, {
                    chatroomId: this.id, page, size, offer,
                },
            )
            return res
        },
        getAllEntities () {
            return this.$store.state.chat.chatroomMsgs
        },
        async onSendMessage (roomId, msg) {
            const res = await this.$store.dispatch(
                rootActions.SEND_CHATROOM_MSG,
                { chatroomId: roomId, msg, offer: this.offer },
            )
            this.onCreateEntityPagination(res.data)
        },
        onLoadMore () {
            if (this.id == null) return
            this.page += 1
        },
        handleCustomActions () {
            this.visibleCreatePrivateProductForm = true
        },
        async onCreatePrivateProduct (product) {
            this.offer = product
            await this.onSendMessage(this.id, '')
            this.visibleCreatePrivateProductForm = false
        },
    },
}
</script>
