var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VChatRoom", {
        key: _vm.selectedRoomId,
        attrs: { id: _vm.selectedRoomId },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(chatroom) {
              return [
                _c("ChatWindow", {
                  attrs: {
                    "show-search": false,
                    "auto-scroll": {
                      send: { new: true, newAfterScrollUp: true },
                      receive: { new: true, newAfterScrollUp: true }
                    },
                    "message-actions": [],
                    "show-add-room": false,
                    "show-audio": false,
                    "show-files": false,
                    "show-emojis": false,
                    "show-reaction-emojis": false,
                    "load-first-room": false,
                    rooms: _vm.rooms,
                    messages: _vm.mergeMsgsToNewMsg(
                      chatroom.msgs,
                      chatroom.newMsgs
                    ),
                    "current-user-id": _vm.me.id,
                    "room-id": _vm.selectedRoomId,
                    "room-actions": _vm.roomActions,
                    "loading-rooms": _vm.firstPageLoading,
                    "rooms-loaded": _vm.roomsLoaded,
                    "messages-loaded": chatroom.isLastPage,
                    "menu-actions": _vm.menuActions,
                    "message-selection-actions": _vm.messageSelectionActions,
                    "textarea-action-enabled": true
                  },
                  on: {
                    "textarea-action-handler": chatroom.handleCustomActions,
                    "send-message": function(ref) {
                      var roomId = ref.roomId
                      var content = ref.content

                      return chatroom.onSendMessage(roomId, content)
                    },
                    "fetch-more-rooms": _vm.onFetchMoreRoom,
                    "fetch-messages": function(ref) {
                      var room = ref.room
                      var options = ref.options

                      return _vm.onFetchMsgs(room, options, chatroom.onLoadMore)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "custom-action-icon",
                        fn: function() {
                          return [
                            _c("a-icon", {
                              style: { fontSize: "24px", color: "#08c" },
                              attrs: { type: "plus-circle" }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "custom-message-attachments",
                        fn: function(ref) {
                          var attachments = ref.attachments
                          return [
                            attachments && attachments.length > 0
                              ? _c(
                                  "div",
                                  { staticStyle: { width: "380px" } },
                                  [
                                    _c("ProductCard", {
                                      attrs: {
                                        product: _vm.getProductFromId(
                                          attachments[0].id
                                        ),
                                        "disable-update": true,
                                        "enable-click-to-open-update-form": true
                                      },
                                      on: {
                                        duplicate: function(value) {
                                          return chatroom.onCreatePrivateProduct(
                                            value
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }